// 1.0  引入 axios
import axios from 'axios';

import {Message} from 'element-ui';

// 2.0   baseURL   timeout 
// const api= window.location.origin.indexOf('localhost') == -1 ? 'https://xlmdyapi.smfyun.com' : '/api/'
// axios.defaults.baseURL = '/api';
const api = 'https://xlmdyapi.smfyun.com';
axios.defaults.baseURL = 'https://xlmdyapi.smfyun.com';
// axios.defaults.baseURL = window.location.origin.indexOf('localhost') == -1 ? 'https://xlmdyweb.smfyun.com/api' : '/api'

axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.timeout = 30000;

// 3.0 拦截器
// 请求拦截器
axios.interceptors.request.use((config) => {
  return config;
});
// 响应拦截器
axios.interceptors.response.use(response => {
  const res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 200) {
    // Token expired;
    if (res.code === 401 || res.code === 500 || res.code === 40001 || res.code === 40003) {
      let msg = res.msg || res.Message;
      console.log(msg);
      Message.error(msg);
    } else {
      let msg = res.msg || res.Message;
      const newMsg = msg.replace(/(\n|\r|\r\n|↵)/g, '<br/>');
      console.log(msg);
      Message({
        type: 'error',
        message: newMsg,
        dangerouslyUseHTMLString: true,
      });
    }

    return Promise.reject(res.msg || 'Error');
  } else {
    return res;
  }
},
error => {
  if (String(error).indexOf('timeout') != -1) {
    return Promise.reject(error);
  } else {
    const res = error.response.data;
    if (res.code === 500) {
      Message.error(res.msg);
    }
    return Promise.reject(error);
  }
});

export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {params})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // 处理失败的请求
          reject(err);
        });
    });
  },
  post(url, query, header) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, query, {headers: header})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // 处理失败的请求
          reject(err);
        });
    });
  },
  put(url, params = {}, timeouts = 5000) {
    return new Promise((resolve, reject) => {
      axios.put(url, params, {timeout: timeouts}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },
  delete(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.delete(url, params).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },
  patch(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.patch(url, params).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err);
      });
    });
  }
};
